/* AppHeader.css */

.App-header {
  position: absolute; /* or fixed */
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 9vh;
  max-height: 9vh;
  font-size: calc(10px + 2vmin);
  z-index: 1000;
  color: white;
}

.header-logo {
  margin-left: 20px; 
  height: 40px; 
}
