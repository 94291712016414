/* General styles */
body, .App {
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.App-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* ThreeContainer styles */
.fullscreen-container {
  position: relative;
  height: 90vh;
  z-index: 1; 
  padding: 0;
  overflow: hidden;
}

/* App-Hero styles */
.App-Hero {
  display: flex;
  position: relative;
  width: 100%;
  height: 90vh;
  background-color: rgb(212, 215, 225); /* same color as threejs background */
}

.panels-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.export-note {
  font-size: small;
  color: #666;
  margin-top: 0px;
}



@media (min-width: 1024px) {
  .App-Hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .control-panel {
    position: absolute;
    top: 10vh;
    left: 0;
    z-index: 1000;
    width: 30vh;
    margin: 2vh;
  }

  .export-panel {
    position: absolute;
    top: 10vh;
    right: 0;
    z-index: 1000;
    width: 30vh;
    margin: 2vh;
  }
}

/* iPad Pro specific styles */
@media (max-width: 1024px) and (min-height: 1366px) {
  .App-Hero {
    flex-direction: row; /* Align panels horizontally */
    justify-content: space-between;
  }

  .control-panel, .export-panel {
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 2vh;
    top: 0vh;
  }

  .control-panel {
    align-self: flex-start;
  }

  .export-panel {
    align-self: flex-end;
  }

  .panels-container {
    width: 100%;
    justify-content: space-between;
    height: 100%;
  }

}

/* Mobile styles */
@media (max-width: 1024px) {
  .App-Hero {
    flex-direction: column;
    height: 150vh;
  }

  .fullscreen-container {
    height: 130vh;
  }

  .MasterNounster {
    height: 130vh; /* Extend height on mobile */
    width: 100%;
  }

  .panels-container {
    flex-direction: row;
    width: 100%;
    height: 75vh; /* Adjust based on desired height for panels */
    position: absolute;
    bottom: 0;
    z-index: 2000;
  }

  .control-panel {
    width: 50%;
    height: 100%; /* Full height of the panels container */
    background-color: rgba(0, 0, 0, 0.2);
    padding: 3vh;
  }

  .export-panel {
    width: 50%;
    height: 100%; /* Full height of the panels container */
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 3vh;
  }

  .export-button {
    width: 80%;
  }

  .dropdown label {
    display: block;
    margin-bottom: 3px;
    color: white;
    font-size: xx-small;
  }
  
  .dropdown select {
    width: 100%;
    padding: 5px;
    color: black;
    font-size: small;
  }
}

/* Button styles */
.generate-button, .export-button {
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  font-weight: 700;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.generate-button {
  background-color: rgb(179, 0, 255);
  padding: 20px;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(179, 0, 255, 1) 0%, rgb(204, 1, 255) 50%, rgb(82, 24, 107) 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.export-button {
  background-color: rgb(148, 0, 211);
  margin-top: 10px;
}

.generate-button:hover, .export-button:hover {
  background-color: rgb(147, 51, 234);
  transform: scale(1.05);
}

/* Dropdown styles */
.dropdown {
  margin-bottom: 10px;
  width: 90%;
  margin-left: 5%;
}

.dropdown label {
  display: block;
  margin-bottom: 3px;
  padding-top: 5px;
  color: black;
  font-size: small;
  font-weight: 700;
  margin: 0;
  padding-left: 10px;
}

.dropdown select {
  width: 100%;
  padding: 5px;
  color: black;
}

/* Section styles */
.App-Section1, .App-Section2 {
  flex-grow: 1;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  z-index: -100;
  margin: 0;
  padding: 0;
}

.full-width-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: transparent;
}

.text-container {
  flex: 1;
  padding-right: 20px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.title-text{
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 90px;
}

.trait-display {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-x: auto; /* Add this if there are many traits to ensure they don't overflow */
  font-size: small;
}

@media (max-width: 1024px) {
  .trait-display {
    display: none;
  }
}

.trait-item {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.palette-colors {
  display: flex;
  gap: 4px;
  margin-top: 0px;
  margin-left: 5px;
  
}

.color-square {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
}

/* Footer */
.App-footer {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(18, 18, 19);
  z-index: 100;
  position: relative;
  color: white;
}

.footerNote {
  font-size: small;
  padding: 20px;
}